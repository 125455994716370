import React from "react";
import { Link } from "react-router-dom";

import { toAbsoluteUrl } from "../../_helpers/utils";
import MenuConfig from "../../_helpers/MenuConfig";
import Popup from "../Popup";

import styles from "./Footer.module.scss";

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footer__top}>
                <div className={styles.footer__top_logo}>
                    <img
                        src={`${toAbsoluteUrl("/logo-white.webp")}`}
                        alt="logo-white"
                    />
                </div>
                <div className={styles.footer__top_contact}>
                    <h5 className="wow fadeInUp" data-wow-delay=".3s">
                        Contact
                    </h5>
                    <p>
                        75 Abate Rigord St <br />
                        Ta' Xbiex, XBX 1120 <br />
                        Malta <br />
                        <a href="mailto:contact@everest.org">
                            contact@everest.org
                        </a>
                        <br />
                        <a href="tel:+35625466021">+(356) 2546-6021</a>
                    </p>
                </div>
                <div className={styles.footer__top_company}>
                    <h5 className="wow fadeInUp" data-wow-delay=".3s">
                        Company
                    </h5>
                    {MenuConfig.header.companyPages.map((company) => (
                        <div
                            key={company.id}
                            className={styles.footer__top_links}
                        >
                            <Link to={company.url}>{company.title}</Link>
                        </div>
                    ))}
                </div>
                <div className={styles.footer__top_resources}>
                    <h5 className="wow fadeInUp" data-wow-delay=".3s">
                        Resources
                    </h5>
                    {MenuConfig.header.resourcePages.map((resource) => (
                        <div
                            key={resource.id}
                            className={styles.footer__top_links}
                        >
                            <a href={resource.url}>{resource.title}</a>
                        </div>
                    ))}
                </div>
                <div className={styles.footer__top_legal}>
                    <h5 className="wow fadeInUp" data-wow-delay=".3s">
                        Legal
                    </h5>
                    {MenuConfig.header.legalPages.map((legal) => (
                        <div
                            key={legal.id}
                            className={styles.footer__top_links}
                        >
                            <Link to={legal.url}>{legal.title}</Link>
                        </div>
                    ))}
                    <Popup buttonText="MFSA" />
                </div>
            </div>

            <div className={styles.footer__bottom}>
                {MenuConfig.header.socialPages.map((social) => (
                    <div key={social.id}>
                        <a
                            href={social.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={toAbsoluteUrl(social.icon.footer)}
                                alt={social.title}
                            />
                        </a>
                    </div>
                ))}
            </div>
        </footer>
    );
};

export default Footer;
