import React from "react";

import { toAbsoluteUrl } from "../../_helpers/utils";

import styles from "./ExchangeReward.module.scss";

const ExchangeReward = () => {
    return (
        <a className={styles.home__reward} href="https://exchange.everest.org" rel="noreferrer" target="_blank">
            <div className={styles.home__reward_item}>
                <img
                    className={styles.home__reward_icon}
                    src={toAbsoluteUrl("images/exchange-gift-icon.svg")}
                    alt="gift"
                    width={25}
                />
                Get a <span className={styles.home__reward_text_blue}>$25</span> Welcome
                Gift <span className={styles.home__reward_text_blue}>+</span>&nbsp;
                Earn Over <span className={styles.home__reward_text_blue}>$25,000</span> in
                Referral Bonuses
            </div>
        </a>
    );
};

export default ExchangeReward;
